import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [2,4,5,9];

export const dictionary = {
		"/(main)": [~13,[2],[3]],
		"/(main)/ai": [14,[2,4],[3]],
		"/(unauth)/health": [22],
		"/(unauth)/login": [23,[11]],
		"/(unauth)/login/callback": [~24,[11]],
		"/(main)/logout": [15,[2],[3]],
		"/(unauth)/policy": [~25,[12]],
		"/(main)/profile": [16,[2,5],[3]],
		"/(main)/projects": [17,[2,6],[3,7]],
		"/(main)/projects/[projectDisplayId]": [~18,[2,6,8],[3,7]],
		"/(main)/talks": [19,[2,9],[3,10]],
		"/(main)/talks/[channelDisplayId]": [~20,[2,9],[3,10]],
		"/(main)/terms_and_privacy": [~21,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';